/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $(".search-button").click(function(){
			var bAlreadyOpen = $(".search-box").hasClass('open');

			if(bAlreadyOpen) {
	          $(".search-box").removeClass('open');
	          $("body").removeClass('search-open');
			} else {
				$("body").addClass('search-open');
				$(".search-box").addClass('open');
				$('.searchinput').focus();
			}
        });

        $(".close-search").click(function(){
          $(".search-box").removeClass('open');
          $("body").removeClass('search-open');
        });

        $(".nav-butt").click(function(){
          $('.mobile-menu').slideToggle();
          $('.open-menu').toggleClass('hide');
          $('.close-menu').toggleClass('hide');
        });

        $(".sub-menu-butt").click(function(){
          $(this).toggleClass('menu-open menu-close');
          $(this).next(".sub-menu").slideToggle();
        });

        $('.sub-menu').has('li.menu-item-has-children').addClass('has-children');

        $('.contact-button, .enquire-cta').click(function(){
          $('.button-text').toggleClass('form-close');

        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
		var oTweet = $('.tweet');
        oTweet.twittie({
            dateFormat: '%b. %d, %Y',
            template: '{{tweet}}',
            count: 4,
            hideReplies: true
        }, function() {
			$('ul', oTweet).slick({
				arrows: false,
				slidesToShow: 3,
				responsive: [{
					breakpoint: 768,
					settings: {
						slidesToShow: 1
					}
				}]
			});
		});

        $(".logos-carousel").owlCarousel({
          loop:true,
          margin:10,
          autoplay:true,
          autoplayTimeout:2000,
          //animateOut: 'fadeOut',
          pagination: false,
          dots: false,
          nav: false,
          mouseDrag: false,
          smartSpeed:1500,
          rewindNav:false,
          
          responsive:{
            0:{
              items:2,
            },
            600:{
              items:3,
            },
            1000: {
              items: 4,
            },
          }
        });

        $(".testimonial-carousel").owlCarousel({
          items:1,
          loop:true,
          margin:10,
          autoplay:true,
          autoplayTimeout:5000,
          //animateOut: 'fadeOut',
          pagination: false,
          dots: false,
          nav: false,
          mouseDrag: false,
        });

        $('.cycle-slideshow').cycle({
          fx: 'fade',
          autoHeight: 'container',
          timeout: 0,
          slides: '> div',
          prev: '#prev',
          next: '#next',
          allowWrap: false,
        });

        // external js: isotope.pkgd.js

      var $container = $('#customer-container'),
        $select = $('div.filterGroup select');
        filters = {};

        $container.isotope({
            itemSelector: '.grid-item'
        });
            $select.change(function() {
            var $this = $(this);

            var $optionSet = $this;
            var group = $optionSet.attr('data-filter-group');
        filters[group] = $this.find('option:selected').attr('data-filter-value');

            var isoFilters = [];
            for (var prop in filters) {
                isoFilters.push(filters[prop]);
            }
            var selector = isoFilters.join('');

            $container.isotope({
                filter: selector
            });

            return false;
        });

        $(".scroll-down").click(function() {
          $('html, body').animate({
            scrollTop: $(".scroll-point").offset().top
          }, 2000);
        });

        var header = $('.bg-image-overlay');
        var range = 300;

        $(window).on('scroll', function () {

          var scrollTop = $(this).scrollTop(),
              height = header.outerHeight(),
              offset = height / 2,
              calc = 1 - (scrollTop - offset + range) / range;

          header.css({ 'opacity': calc });

          if (calc > '1') {
            header.css({ 'opacity': 1 });
          } else if ( calc < '0' ) {
            header.css({ 'opacity': 0 });
          }

        });

        $('.work-for-us-job-title').click(function(){
          $(this).next('.job-description').slideToggle();
          $(this).toggleClass('accordion-open accordion-close');
        });

        $('.status-title').click(function(){
          $(this).next('.job-description').slideToggle();
          $(this).toggleClass('accordion-open accordion-close');
        });

        $('.accordion-title').click(function(){
          $(this).next('.accordion-description').slideToggle();
          $(this).toggleClass('accordion-open accordion-close');
        });

        $(document).scroll(function () {
            var y = $(this).scrollTop();
            if (y > 150) {
                $('.bottom-menu').fadeIn();
            } else {
                $('.bottom-menu').fadeOut();
            }

        });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $(".slider-carousel").owlCarousel({
          items:1,
          loop:true,
          margin:10,
          autoplay:true,
          autoplayTimeout:5000,
          //animateOut: 'fadeOut',
          pagination: false,
          dots: false,
          nav: true,
          mouseDrag: true,
        });

        $(".testimonial-carousel").owlCarousel({
          items:1,
          loop:true,
          margin:10,
          autoplay:true,
          autoplayTimeout:5000,
          //animateOut: 'fadeOut',
          pagination: false,
          dots: false,
          nav: false,
          mouseDrag: false,
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);



        $('.open-popup-link').magnificPopup({
          type:'inline',
          midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
        });

  	/**
	 * Toggle the open state of the bottom menu contact form
	 * @return		void
  	 */
  	function fnBottomMenuToggle() {
  		$('.header-container .contact-form').toggleClass('open');
      $('body').toggleClass('contact-open');
  	}

	/**
	 *
	 */
	function fnLVRTLoadYoutubeThumbnail(sVideoLink, fnCallback) {
		var aMatches = sVideoLink.match(/youtube\.com\/watch\?v=([a-zA-Z0-9\-\_]+)/i);

		if(aMatches === null || !aMatches.length) {
			return;
		}

		//
		var sImageUrl = '//img.youtube.com/vi/' + aMatches[1] + '/hqdefault.jpg';
		fnCallback(sImageUrl);
	}

	/**
	 *
	 */
	function fnLVRTLoadVimeoThumbnail(sVideoLink, fnCallback) {
		var aMatches = sVideoLink.match(/vimeo.com\/([0-9]+)/i);

		if(aMatches === null || !aMatches.length) {
			return;
		}

		// Pull and parse the API response
		var sApiUrl = 'https://vimeo.com/api/v2/video/' + aMatches[1] + '.json';

		$.ajax({
			'dataType': 'json',
			'type': 'GET',
			'url': sApiUrl
		}).done(function(aResponse) {
			if(aResponse instanceof Array && aResponse.length > 0) {
				fnCallback(aResponse[0].thumbnail_large);
			}
		});
	}

	/**
	 * Set up the Left Video - Right Text content block
	 * @return		void
	 */
	function fnLVRTSetup(i, oContainer) {
		oContainer = $(oContainer);
		oVideoPreview = $('.video-preview', oContainer);

		// Load the preview thumbnail
		var fnThumnailFetch = (oVideoPreview.attr('href').indexOf('vimeo') >= 0) ? fnLVRTLoadVimeoThumbnail : fnLVRTLoadYoutubeThumbnail;
		fnThumnailFetch(oVideoPreview.attr('href'), function(sThumbnailUrl) {
			var sValue = ['url(', sThumbnailUrl, ')'].join('');
			oVideoPreview.css('background-image', sValue);
		});

		// Add Magnific popup
		$(oVideoPreview).magnificPopup({
			disableOn: 700,
			fixedContentPos: false,
			mainClass: 'mfp-fade',
			preloader: false,
			removalDelay: 160,
			type: 'iframe'
		});
	}

	/**
	 *
	 */
	function fnFloatingHeaderResize(oEvent) {
		var iHeight = $('.header-floater').outerHeight();
		$('.header-container').css('min-height', iHeight);
	}

	/**
	 *
	 */
	function fnFloatingHeaderMinimise() {
		var oHeaderContainer = $('.header-container');
		var oClientRect = oHeaderContainer[0].getBoundingClientRect();
		var bMinimalHeader = (oClientRect.bottom < 0);
		$('body').toggleClass('minimal-header', bMinimalHeader);
	}

	/**
	 *
	 */
	function fnFloatingHeaderSetup(i, oContainer) {
		$(window).on('resize', fnFloatingHeaderResize);
		$(window).on('scroll', fnFloatingHeaderMinimise);
	}

	/**
	 * The DOM is ready
	 * @return		void
	 */
	function fnDomReady() {
		$('.left-video-right-text').each(fnLVRTSetup);
		$('.header-container').each(fnFloatingHeaderSetup);

		// Toggle the open state of the bottom menu contact form
		$('body').on('click', '.bottom-menu-toggle', function(oEvent) {
			oEvent.preventDefault();
			fnBottomMenuToggle();
		});
	}

	/**
	 * The window has loaded
	 * @return		void
	 */
	function fnWindowLoad() {
		$(window).trigger('resize');
		$(window).trigger('scroll');
	}

	//
	$(document).on('ready', fnDomReady);
	$(window).on('load', fnWindowLoad);

})(jQuery); // Fully reference jQuery after this point.
